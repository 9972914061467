<template>
  <v-container fluid>
    <TableViewComponent
      title="Table structures juridiques > qualité du représentant légal"
      @addItemEvent="onAddStatus()"
      addItemLabel="ajouter une qualité de représentant légal"
      itemLabel="qualité de représentant légal"
      itemsLabel="qualités de représentant légal"
      :items="statuts"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette qualité de représentant légal ?"
      :pagination="false"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { LegalRepresentativeStatusService } from "@/service/etablishment/legal_representative_status_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";



export default {
  name: "TableLegalRepresentativeStatus",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les éléments */
      statuts: [],
    };
  },
  methods: {
    onAddStatus() {
      this.$router.push(routes.legalRepresentativeStatus.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.statuts = [];

        let statuts = await this.service.getAll();

        statuts.forEach((r) => {
          let status = JSON.parse(JSON.stringify(r));

          //action de consultation
          status.view = () => {
            this.$router.push(
              routes.legalRepresentativeStatus.view.root + "/" + status.id
            );
          };

          //action de modification
          status.edit = () => {
            this.$router.push(
              routes.legalRepresentativeStatus.edit.root + "/" + status.id
            );
          };

          //action de suppression
          status.delete = async () => {
            try {
              await this.service.delete(status.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression du statut : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.statuts.push(status);

          //   console.log(JSON.stringify(this.statuts, null, 4));
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Label",
          align: "start",
          sortable: true,
          value: "label",
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditJuridique];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditDictionary, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadJuridique];
    },
  },
  mounted() {
    this.service = new LegalRepresentativeStatusService(
      this.$api.getLegalRepresentativeStatusApi()
    );

    this.load();
  },

};
</script>

<style>
</style>